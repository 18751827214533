import { BrowserRouter as Router } from 'react-router-dom';
import RoutesConfig from '../../routes/RoutesConfig';
import "../../style/App.css";

const App = () => {
  return (
    <div id="root">
      <div id="app-root">
        <Router>
              <RoutesConfig />
        </Router>
      </div>
    </div>
  );
};

export default App;
