import React from 'react';
import { useLocation } from 'react-router-dom';

function AnimationView() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const animationPath = queryParams.get('path');
  const decodedPath = decodeURIComponent(animationPath);

  // Check if the path is for an MP4 file
  const isMp4 = decodedPath.endsWith('.mp4');

  return (
    isMp4 ? (
      // Render a video element for MP4 files
      <video 
        src={decodedPath} 
        title="Animation" 
        style={{ width: '100%', height: '100vh' }} 
        controls 
      />
    ) : (
      // Render an iframe for other types of content
      <iframe
        src={decodedPath}
        title="Animation"
        style={{ width: '100%', height: '100vh', border: 'none' }}
      />
    )
  );
}

export default AnimationView;
