import { createContext, useReducer, useEffect } from "react";
import { alreadySigned, logout } from "../constants/states";
import { authReducer } from "../reducers/authReducer";
import {Auth, Hub } from 'aws-amplify';
export const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {

  const initialState = {
    user: null,
    authIsReady: false,
    userRecord: null,
  };
  
  const [state, dispatch] = useReducer(authReducer, initialState);

  useEffect(() => {
    // Check if the user is authenticated when component mounts
    const checkUser = async () => {
      try {
        const usr = await Auth.currentAuthenticatedUser();
        dispatch({ type: alreadySigned, payload: usr });
      } catch (error) {
        // console.log("User is not signed in");
      }
    };

    checkUser();
    
    // Optional: Add listeners for auth events if required
    const authListener = Hub.listen("auth", (data) => {
      switch (data.payload.event) {
        case "signIn":
          // console.log("A user has signed in!",  data.payload.data );
          dispatch({ type: alreadySigned, payload: data.payload.data });
          break;
        case "signOut":
          // console.log("A user has signed out!");
          dispatch({ type: logout, payload: null });
          break;
        default:
          break;
      }
    });

    return () => {
      authListener();
  };

  }, []);

  return (
    <AuthContext.Provider value={{ ...state, dispatch }}>
      {children}
    </AuthContext.Provider>
  );
};
