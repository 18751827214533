import React from "react";
import "@aws-amplify/ui-react/styles.css";
import { Button, Pagination } from "antd";
import useLandingPage from "../../hooks/useLandingPage";
import LandingPageHeader from "./LandingPageHeader";
import { VideoCameraOutlined, PlayCircleOutlined } from "@ant-design/icons";
function LandingPage() {
  const {
    loading,
    currentItems,
    setSearch,
    loadAnimation,
    paginate,
    filteredPaths,
    itemsPerPage,
    setItemsPerPage,
    currentPage,
  } = useLandingPage();

  const handleItemsPerPageChange = (current, size) => {
    setItemsPerPage(size);
  };

  return (
    <div>
      <LandingPageHeader loading={loading} setSearch={setSearch} />
      {!loading && filteredPaths && (
        <>
          <ul id="myUL">
            {currentItems.map((animation, index) => (
              <li key={index}>
                <Button
                  onClick={() => loadAnimation(animation.path)}
                  style={{
                    marginTop: "5px",
                    padding: "3px",
                    width: "100%",
                    textAlign: "left",
                  }}
                >
                  <span style={{ marginRight: "10px" }}>{animation.name}</span>
                  <span>
                    {animation.path.endsWith(".mp4") ? (
                      <VideoCameraOutlined
                        style={{ verticalAlign: "middle" }}
                      />
                    ) : (
                      <PlayCircleOutlined style={{ verticalAlign: "middle" }} />
                    )}
                  </span>
                </Button>
              </li>
            ))}
          </ul>

          <Pagination
            current={currentPage}
            onChange={paginate}
            total={filteredPaths.length}
            pageSize={itemsPerPage}
            showSizeChanger
            onShowSizeChange={handleItemsPerPageChange}
          />
        </>
      )}
    </div>
  );
}

export default LandingPage;
