import React from "react";
import { useAuthContext } from "../../hooks/useAuthContext";
import { Auth } from "aws-amplify";
import Spinner from "../utils/Spinner";
import { Button } from "@aws-amplify/ui-react";
import { useNavigate } from "react-router-dom";

const LandingPageHeader = ({ loading, setSearch }) => {
  const { user, authIsReady } = useAuthContext();
  const navigate = useNavigate();

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  return (
    <>
      <img src="/logo.png" alt="CDX Learning Systems Canada" />
      {(authIsReady && user !== null) ? (
        <div>
          <h3 style={{ position: "absolute", top: "0px", right: "5px" }}>
            {user?.attributes?.email}
          </h3>
          <Button
            style={{ marginTop: "5px", position: "absolute", top: "50px", right: "5px" }}
            onClick={async () => {
              try {
                await Auth.signOut();
              } catch (error) {
                console.error("Error signing out: ", error);
              }
            }}
          >
            Sign out
          </Button>
        </div>
      ) : (
        <div>
          <Button
            style={{ position: "absolute", top: "60px", right: "5px", backgroundColor: "red", color: "white" }}
            onClick={() => navigate('/login')}
          >
            Login/Register
          </Button>
        </div>
      )}
      <h2>CDX Canada Animations Preview Site</h2>
      <h3>
        These animations provided here are intended as supplemental resources to CDX Canada customers. Unauthorized sharing is prohibited.
      </h3>
      <p>
        Download the{" "}
        <a href="AnimPictorialList.pdf">
          CDX Animations Pictorial Reference Guide
        </a>{" "}
        to find animations of interest. Best way to search below is by file name (second line under each picture i.e. AT_Allison6Speed_C1)
      </p>
      {!loading && (
        <input
          type="text"
          id="myInput"
          onChange={handleSearchChange}
          placeholder="Search for names.."
          title="Type in a name"
          style={{ marginRight: "12px", padding: "5px", height: "50px" }}
        />
      )}
      {loading && (
        <div className="spinner-overlay">
          <Spinner />
        </div>
      )}
    </>
  );
};

export default LandingPageHeader;
