const login = "LOG_IN";
const verifiedEmail = "VERIFY_EMAIL"
const logout = "LOG_OUT";
const alreadySigned = "AUTH_IS_READY";
const light = "light";
const dark = "dark";
const toggle = "toggle";
const expanded = "expanded";
const collapsed = "collapsed";
const toggleMenu = "toggleMenu";
const clickAway = "clickAway";
const assessmentMode = { display: "display" }
const updateSnackbar = "UPDATE_SNACKBAR";

export {
    alreadySigned, assessmentMode,
    clickAway, collapsed, dark, expanded,
    light, login,
    logout, toggle,
    toggleMenu, verifiedEmail,
    updateSnackbar
};


