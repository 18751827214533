import { login, logout, alreadySigned, verifiedEmail } from "../constants/states";

export const authReducer = (state, action) => {
  switch (action.type) {
    case login:
    case verifiedEmail:
      return { ...state, user: action.payload };
    case logout:
      return { ...state, user: null };
    case alreadySigned:
      return { ...state, user: action.payload, authIsReady: true };
    default:
      return state;
  }
};

