import { Authenticator } from "@aws-amplify/ui-react";
import "../../style/LoginPage.css";
import { useEffect } from "react";
import { useAuthContext } from "../../hooks/useAuthContext";
import { useNavigate } from "react-router-dom";

const LoginPage = () => {
  const navigate = useNavigate();
  const { user, authIsReady } = useAuthContext();
  useEffect(()=>{
    if(authIsReady && user){
      navigate("/");
    }
  })
  const formFields = {
    signUp: {
      given_name: {
        order: 1,
      },
      family_name: {
        order: 2,
      },
      password: {
        order: 3,
      },
      confirm_password: {
        order: 4,
      },

    },
  };

  return (
    <div className="authenticator">
    <Authenticator formFields={formFields}>
    </Authenticator>
    </div>

  );
};

export default LoginPage;
