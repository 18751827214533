const ampConfig = {
  Auth: {
    region: "ca-central-1",
    userPoolId: "ca-central-1_YiLnsXBEK",
    userPoolWebClientId: "7j6mpjjvfiskj03hajfmd8s99f",
    oauth: {
      domain: "https://media.cdxlearning.ca/",
      scope: [
        'phone',
        'email',
        'profile',
        'openid',
        'aws.cognito.signin.user.admin',
      ],
      redirectSignIn: "https://media.cdxlearning.ca/",
      redirectSignOut: "https://media.cdxlearning.ca/login/",
      responseType: 'code',
    },
  },
};

export {ampConfig};