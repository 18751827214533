import { Route, Routes } from "react-router-dom";
import React, { useContext } from 'react';
import LandingPage from '../components/home/LandingPage';
import LoginPage from '../components/auth/LoginPage';
import AnimationView from '../components/animation/AnimationView';
import { AuthContext } from '../contexts/AuthContext';

const ProtectedRoute = ({ children }) => {
  const { user } = useContext(AuthContext);
  if (!user) {
    return <LoginPage />
  }
  return children;
};

const RoutesConfig = () => {
  return (
    <div>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/animation-view" element={
          <ProtectedRoute>
            <AnimationView />
          </ProtectedRoute>
        } />
      </Routes>
    </div>
  );
};

export default RoutesConfig;

