import React from "react";
import ReactDOM from "react-dom/client";
import "./style/index.css";
import App from "./components/home/App";
import { AuthContextProvider } from "./contexts/AuthContext";
import "@aws-amplify/ui-react/styles.css";

import { Amplify } from "aws-amplify";
import { ampConfig } from "./aws-exports";
Amplify.configure(ampConfig)
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <AuthContextProvider>
      <App />
    </AuthContextProvider>
  </React.StrictMode>
);
