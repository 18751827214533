import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const useLandingPage = () => {
  const [animationPaths, setAnimationPaths] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [filteredPaths, setFilteredPaths] = useState([]);
  const [search, setSearch] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    fetch("/animationList.json")
      .then((response) => response.json())
      .then((data) => {
        if (data?.paths_and_names) {
          const sortedData = data.paths_and_names
            .filter(animation => animation.name && typeof animation.name === 'string')
            .sort((a, b) => a.name.localeCompare(b.name));
  
          setAnimationPaths(sortedData);
          setFilteredPaths(sortedData);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (animationPaths && search) {
      const filteredAndSorted = animationPaths
        .filter(animation => 
          animation.name.toUpperCase().includes(search.toUpperCase()) ||
          animation.path.toUpperCase().includes(search.toUpperCase())
        )
        .sort((a, b) => a.name.localeCompare(b.name));
      setFilteredPaths(filteredAndSorted);
    }
  }, [search, animationPaths]);
  

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredPaths.slice(indexOfFirstItem, indexOfLastItem);

  const loadAnimation = (animationPath) => {
    navigate(`/animation-view?path=${encodeURIComponent(animationPath)}`);
  };

  // const loadAnimation = (animationPath) => {
  //   const redirectUrl = `https://media.cdxlearning.ca/animation-view?path=${encodeURIComponent(animationPath)}`;
  // window.location.href = redirectUrl;
  // };

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return {
    loading,
    currentItems,
    setSearch,
    loadAnimation,
    paginate,
    filteredPaths,
    itemsPerPage,
    setItemsPerPage,
    currentPage
  };
};

export default useLandingPage;
